export const SONG_LIST = [
  {
    id: "1",
    title: "Chopin",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Chopin.wav",
    play: 0,
  },
  {
    id: "2",
    title: "Don't let me go",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Do_not_let_me_go.wav",
    play: 0,
  },
  {
    id: "3",
    title: "Easy",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Easy.wav",
    play: 0,
  },
  {
    id: "4",
    title: "I'm in love",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/I_am_in_love.wav",
    play: 0,
  },
  {
    id: "5",
    title: "LV u baby",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/LV_u_baby.wav",
    play: 0,
  },
  {
    id: "6",
    title: "Paproch",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Paproch.wav",
    play: 0,
  },
  {
    id: "7",
    title: "Poisone",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Poisone.wav",
    play: 0,
  },
  {
    id: "8",
    title: "Schiz",
    artist: "Ugly Tones",
    src: "https://uglytones01.s3.eu-west-1.amazonaws.com/Schiz.wav",
    play: 0,
  },
];
