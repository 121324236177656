import "./assets/css/bootstrap-icons.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/ut.css";
import Header from "./components/Header";
import Contact from "./components/Contact";
import DisplaySongList from "./components/DisplaySongList";

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <Header />
      </div>
      <div className="container-fluid light py-3">
        <DisplaySongList />
      </div>
      <div className="container-fluid py-5">{/* <Contact /> */}</div>
    </div>
  );
}

export default App;
