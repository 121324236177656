import React, { useEffect, useRef, useState } from "react";

export default function AudioPlayer({ audioSrc, palyedSong, playSong }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(null);

  const handleSeek = (e) => {
    audioRef.current.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
    handleEnd();
  };

  const handlePlay = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  if (isPlaying && playSong == 0) {
    handlePause();
  }

  const handleChange = () => {
    palyedSong(audioSrc.id);
  };

  const handlePlayPause = () => {
    handleChange();
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleEnd = () => {
    if (audioRef.current.currentTime == audioRef.current.duration) {
      setCurrentTime(0);
      setDuration(0);
      handlePause();
    }
  };

  const formatDuration = (durationSeconds) => {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  return (
    <div className="card card-content">
      <div className="d-flex">
        <button onClick={() => handlePlayPause()} id="playButton">
          {isPlaying ? "pause" : "play_arrow"}
        </button>
        <div className="timeline">
          <div>
            <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleSeek}
              className="p-2"
            />
            <audio ref={audioRef} src={audioSrc.src} />
          </div>
          <div className="details">
            <div className="d-flex justify-content-between">
              <p className="time">{formatDuration(currentTime)}</p>
              <p>
                <span className="artist">{audioSrc.artist}</span>
                <span className="spacer">::</span>
                <span className="title">{audioSrc.title}</span>
              </p>
              <p className="time">{formatDuration(duration)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
